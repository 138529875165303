<template>
  <div class="quota-sum">
    <v-container fluid class="pt-6">
      <div class="quota-sum__header">
        <v-row justify="space-between" align="center">
          <v-col cols="2">
            <v-btn fab dark color="info" x-small text @click="goBack">
              <IconPictoAarrowsLeft />
            </v-btn>
          </v-col>
          <v-col class="text-center">
            <v-btn
              v-if="!$store.getters['quota/isQuotaFreese']"
              color="info"
              depressed
              @click="validateQuota"
            >
              FAIRE VALIDER MON DEVIS
            </v-btn>
            <v-btn
              v-if="$store.getters['quota/isQuotaValidated']"
              color="info"
              depressed
              @click="visitMasterQuota"
            >
              VOIR MON DEVIS
            </v-btn>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn
              :loading="loadingGenerateButton"
              fab
              dark
              color="info"
              text
              @click="pdfGeneration"
            >
              <v-icon color="primary">mdi-tray-arrow-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-row justify="center">
        <v-col md="10">
          <div class="quota-sum-page">
            <div class="quota-sum-page__header">
              N° du Devis :
              <span class="quota-number">{{ $route.params.quotaId }}</span>
            </div>
            <div class="quota-sum-page__body">
              <div class="quota-sum-table">
                <div class="quota-sum-table__row quota-sum-table__row--header">
                  <div
                    v-for="(th, thIndex) in thead"
                    :key="`th-${thIndex}`"
                    :class="`quota-sum-table__row--col-${th.key}`"
                  >
                    {{ th.value }}
                  </div>
                </div>

                <template v-for="(room, roomIndex) in list">
                  <div
                    :key="`room-${roomIndex}`"
                    class="quota-sum-table__row quota-sum-table__row--room"
                  >
                    <div class="room-name">{{ room.name }}</div>
                    <div class="room-price">{{ room.price }} TTC</div>
                    <div class="room-action" v-if="showActionButton">
                      <v-btn small text icon :to="room.editIconAction">
                        <IconEdit size="22" />
                      </v-btn>
                      <v-btn v-if="false" small text icon>
                        <IconDelete size="20" color="#a3a3a3" />
                      </v-btn>
                    </div>
                  </div>
                  <template
                    v-for="(category, categoryIndex) in room.categories"
                  >
                    <div
                      :key="`category-${roomIndex}-${categoryIndex}`"
                      class="
                        quota-sum-table__row quota-sum-table__row--category
                      "
                    >
                      <div class="category-name">
                        {{ categoryIndex + 1 }}. {{ category.name }}
                      </div>
                      <div class="category-price">{{ category.price }} TTC</div>
                    </div>
                    <template
                      v-for="(
                        subcategory, subcategoryIndex
                      ) in category.subcategories"
                    >
                      <div
                        :key="`category-${roomIndex}-${categoryIndex}-${subcategoryIndex}`"
                        class="
                          quota-sum-table__row quota-sum-table__row--subcategory
                        "
                      >
                        <div class="subcategory-name">
                          {{ categoryIndex + 1 }}.{{ subcategoryIndex + 1 }}.
                          {{ subcategory.name }}
                        </div>
                        <div class="subcategory-border"></div>
                      </div>
                      <template v-for="(item, itemIndex) in subcategory.items">
                        <div
                          :key="`item-${roomIndex}-${categoryIndex}-${subcategoryIndex}-${itemIndex}`"
                          class="
                            quota-sum-table__row quota-sum-table__row--product
                          "
                        >
                          <template v-for="(product, prIndex) in item">
                            <div
                              v-if="prIndex !== 0"
                              :key="`th-${prIndex}`"
                              :class="{
                                [`quota-sum-table__row--col-${product.key}`]: true,
                                'pt-3': product.distanceTop,
                                'border-bottom': product.borderBottom,
                              }"
                            >
                              <template v-if="product.discountPriceValue && product.discountPriceValue.type === 'single'">
                                {{ product.discountPriceValue.data }}
                              </template>
                              <template v-else-if="product.discountPriceValue && product.discountPriceValue.type === 'multiple'">
                                <div class="d-flex flex-column">
                                  <span class="old-price">{{product.discountPriceValue.data.old_price}}</span>
                                  <span class="discount-price">{{product.discountPriceValue.data.discount_price}}</span>
                                </div>
                              </template>
                              <template v-else>
                                <span>{{ product.value }}</span>
                              </template>
                            </div>
                            <div
                              v-else
                              :key="`th-${prIndex}`"
                              v-html="product.value"
                              :class="{
                                [`quota-sum-table__row--col-${product.key}`]: true,
                                'pt-3': product.distanceTop,
                                'border-bottom': product.borderBottom,
                              }"
                            ></div>
                            <div class="product-action" v-if="showActionButton" :key="`b-${product.key}${itemIndex}`">
                              <v-btn
                                small
                                outlined
                                fab
                                color="primary"
                                rounded
                                @click="discountDialog = true; currentDiscountPrices = {
                                  item: product
                                }"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </div>
                          </template>
                        </div>
                      </template>
                    </template>
                  </template>

                  <template v-if="room.customServices">
                    <div
                      :key="`room-custom-services-${roomIndex}`"
                      class="
                        quota-sum-table__row quota-sum-table__row--category
                      "
                    >
                      <div class="category-name">
                        {{ room.customServices.name }}
                      </div>
                      <div class="category-price">
                        {{ room.customServices.price }} TTC
                      </div>
                    </div>

                    <template
                      v-for="(customService, customServiceIndex) in room
                        .customServices.items"
                    >
                      <div
                        :key="`room-custom-services--${roomIndex}-${customServiceIndex}`"
                        class="
                          quota-sum-table__row quota-sum-table__row--product
                        "
                      >
                        <template v-for="(s, sIndex) in customService">
                          <div
                            v-if="sIndex !== 0"
                            :key="`ths-${sIndex}`"
                            :class="`quota-sum-table__row--col-${s.key}`"
                          >
                            {{ s.value }}
                          </div>
                          <div
                            v-else
                            :key="`ths-${sIndex}`"
                            v-html="s.value"
                            :class="`quota-sum-table__row--col-${s.key}`"
                          ></div>
                        </template>
                      </div>
                    </template>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <DiscountModal
      :show="discountDialog"
      :prices="currentDiscountPrices"
      @closeModal="discountDialog = false"
      @updatePrice="updateDiscountPrice"
    />
  </div>
</template>

<script>
import quotaListMixin from "@/mixins/quotasList.js";
import offerSumDataMixin from "../mixin/offerSum.js";
import { QuotaRoomProductsService } from "@/services/product.service.js";
import { RoomServicesService } from "@/services/roomServices.sevice.js";

export default {
  name: "QuotaSum",
  mixins: [quotaListMixin, offerSumDataMixin],
  components: {
    IconPictoAarrowsLeft: () => import("~cp/Icons/IconPictoAarrowsLeft"),
    IconEdit: () => import("~cp/Icons/IconEdit"),
    IconDelete: () => import("~cp/Icons/IconDelete"),
    DiscountModal: () => import("../DiscountModal/DiscountModal.vue")
  },
  data() {
    return {
      thead: [
        {
          key: "name",
          value: "Descriptif de la prestation et/ou réalisation de rénovation",
        },
        {
          key: "price",
          value: "Prix Unitaire HT don fourniture(s)",
        },
        {
          key: "amount",
          value: "Quantité",
        },
        {
          key: "unit",
          value: "Unité",
        },
        {
          key: "total",
          value: "Total TTC",
        },
      ],
      keysOfRow: ["room", "category", "subcategory"],
      discountDialog: false,
      currentDiscountPrices: {
        discount_fixed: null,
        discount_percentage: null
      }
    };
  },
  filters: {
    price(value) {
      if (!value && value !== 0) return "";
      return value.toFixed(2) + "  €";
    },
  },
  computed: {
    showActionButton() {
      return (
        this.$store.getters["authModule/isSuperUser"] &&
        !this.$store.getters["quota/isQuotaSigned"]
      );
    },
    list() {
      return this.quotaRoomsTable?.list || [];
    },
  },
  methods: {
    validateQuota() {
      this.$router.push({
        name: "QuotaAddDocument",
        params: {
          quotaId: this.$route.params.quotaId,
        },
      });
    },
    visitMasterQuota() {
      this.$router.push({
        name: "QuotaVisitMaster",
        params: {
          quotaId: this.$route.params.quotaId,
        },
      });
    },
    goBack() {
      let currentQuota = this.$store.state.quota.currentQuota;
      if (!currentQuota?.quota_rooms[0]) {
        this.$router.go(-1);
        return false;
      }
      if (
        !this.$store.getters["authModule/isSuperUser"] &&
        this.$store.getters["quota/isQuotaFreese"]
      ) {
        this.$router.push({ name: "Home" });
        return false;
      }
      this.$router.push({
        name: "RoomId",
        params: {
          roomId: currentQuota?.quota_rooms[0]?.id,
        },
        query: {
          project_id: currentQuota?.project_id,
          quota_id: currentQuota?.quota_rooms[0]?.quota_id,
        },
      });
    },
    getQuotaInformation(){
      this.$store.dispatch('quota/getQuotaById', this.$route.params.quotaId)
      this.getQuotaRoomServices()
      this.getQuotaRoomProducts()
      this.getRoomCategories()
    },
    updateDiscountPrice(payload) {
      if(payload.item.type === "product") {
        let updProductData = {
          quota_room_service_id: payload.item.quota_room_service_id,
          quota_room_id: payload.item.quota_room_id,
          service_id: payload.item.service_id,
          discount_percentage: payload.discount_percentage,
          discount_fixed: payload.discount_fixed,
        }
        QuotaRoomProductsService.updateProduct(payload.item.id, updProductData).then( () => {
          this.getQuotaInformation()
        });
        return;
      }

      if(payload.item.type === "service") {
        let updServiceData =  {
          quota_room_id: payload.item.quota_room_id,
          category_id: payload.item.category_id,
          amount: payload.item.amount,
          discount_percentage: payload.discount_percentage,
          discount_fixed: payload.discount_fixed,
        };
        RoomServicesService.updateQuotaRoomService(payload.item.id, updServiceData)
          .then(() => {
            this.getQuotaInformation()
          })
          .catch((ex) => console.log(ex));
        return;
      }

    },
  },
};
</script>

<style src="./QuotaSum.scss" lang="scss" scoped></style>